.largeAboutText{
    text-align: center;
}

.icon-wrapper{
    text-align: center;
}

.icon{
    font-size: 120px;
    margin-bottom: 50px;
    color: #7625ad;
}

.icon-list{
    margin-top: 120px;
}

.welcomeText{
    display: block;
}

@media only screen and (max-width: 600px) {
    .largeAboutText{
        margin-top:20px;
        font-size: 30px;
    }

    .icon{
        font-size: 50px;
        margin-bottom: 20px;
        margin-top: 40px;
        color: #7625ad;
    }
}