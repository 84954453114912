.carousel{
    padding-left: 20%;
    padding-right: 20%;
    background-color: rgb(240, 240, 240);
    padding-top: 50px;
    padding-bottom: 50px;
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-indicators > li{
    background-color: black;
}

@media only screen and (max-width: 600px) {
    .carousel{
        padding-left: 5%;
        padding-right: 5%;
        background-color: rgb(240, 240, 240);
        padding-top: 20px;
        padding-bottom: 20px;
    }
}