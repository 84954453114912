.case-study {
  width: 100%;
  background: #28243d;
  font-family:"Lato", sans-serif;
  color: #f7f7f8;
  &-title {
    margin-top: 20px;
    font-size: 48px;
    font-weight: bold;
    margin-left: 30px;
    &-dot {
      margin-left: 15px;
      font-size: 0.35em;
      vertical-align: top;
      margin-top: 2em;
    }
  }
  &-subtitle {
    font-size: 20px;
    text-align: justify;
    margin-top: 50px;
    &-highlight {
      color: #578c6c;
    }
  }
  &-metrics-chart {
    color: white;
    padding: 50px 0 0 0;
    height: 450px;
    margin-right: 15px;
    margin-left: -15px;
    font-family:"Lato", sans-serif;
    &-track-information {
      height: 125%;
      padding-right: 10px;
      top: 12%;
      position: absolute;
      color: rgba(255, 255, 255, 0.7);
      border-right: 4px dashed rgba(255, 255, 255, 0.5);
      font-size: 13px;
      &.track-release {
        left: 15%;
        display: flex;
        align-items: flex-start;
        p {
          margin-top: 50px;
          vertical-align: top;
        }
      }
      &.track-hits {
        left: 60%;
        display: flex;
        align-items: flex-start;
        border-right:none;
        padding-right: 0;
        padding-left: 10px;
        border-left: 4px dashed rgba(255, 255, 255, 0.5);
        p {
          vertical-align: top;
          margin-top: 50px;
        }
      }
    }
    &-discovery {
      height: 125%;
      padding-left: 10px;
      margin-top: 5px;
      position: absolute;
      border-left: 4px solid #ef4a96;
      left: 27%;
      p {
        font-size: 13px;
        color: #ef4a96;
        width: 150px;
        margin-top: 50px;
      }
    }
    &-legends {
      display: flex;
      width: 50%;
      margin: auto;
      margin-top: 20px;
      margin-bottom: 50px;
      padding: 20px;
      justify-content: space-around;
      border: 1px solid white;
      height: 86px;
      &__label {
        display: flex;
        justify-content: space-evenly;
        width: 45%;
        &--plays,
        &--listeners {
          height: 10px;
          align-self: center;
          width: 20%;
          background: #578c6c;
        }
        &--listeners {
          background: #a5775e;
        }
        p {
          width: 35%;
        }
      }
    }
  }
  @media (max-width: 1920px) and (min-width: 1834px) {
    .artist-image {
      padding-right: 0;
      &--img {
        width: 90%;
      }
    }
    &-title {
      margin-left: 0px;
      font-size: 36px;
    }
    &-subtitle {
      font-size: 19px;
    }
    &-metrics-chart {
      &-track-information {
        height: 127%;
        &.track-release {
          left: 15%;
        }
        &.track-hits {
          left: 60%;
        }
      }
      &-discovery {
        height: 127%;
        left: 27%;
      }
    }
  }
  @media (max-width: 1833px) and (min-width: 1668px) {
    .artist-image {
      padding-right: 0;
      &--img {
        width: 90%;
      }
    }
    &-title {
      margin-left: 0px;
      font-size: 36px;
    }
    &-subtitle {
      font-size: 19px;
    }
    &-metrics-chart {
      &-track-information {
        height: 120%;
        &.track-release {
          left: 15%;
        }
        &.track-hits {
          left: 60%;
        }
      }
      &-discovery {
        height: 120%;
        left: 27%;
      }
    }
  }
  @media (max-width: 1667px) and (min-width: 1608px) {
    .artist-image {
      padding-right: 0;
      &--img {
        width: 90%;
      }
    }
    &-title {
      margin-left: 0px;
      font-size: 36px;
    }
    &-subtitle {
      font-size: 19px;
    }
    &-metrics-chart {
      &-track-information {
        height: 109%;
        &.track-release {
          left: 15%;
        }
        &.track-hits {
          left: 59%;
        }
      }
      &-discovery {
        height: 109%;
        left: 28%;
      }
    }
  }
  @media (max-width: 1607px) and (min-width: 1553px) {
    .artist-image {
      padding-right: 0;
      &--img {
        width: 90%;
      }
    }
    &-title {
      margin-left: 0px;
      font-size: 36px;
    }
    &-subtitle {
      font-size: 19px;
    }
    &-metrics-chart {
      &-track-information {
        height: 105%;
        &.track-release {
          left: 16%;
        }
        &.track-hits {
          left: 59%;
        }
      }
      &-discovery {
        height: 105%;
        left: 29%;
      }
    }
  }
  @media (max-width: 1552px) and (min-width: 1481px) {
    .artist-image {
      padding-right: 0;
      &--img {
        width: 90%;
      }
    }
    &-title {
      margin-left: 0px;
      font-size: 36px;
    }
    &-subtitle {
      font-size: 19px;
    }
    &-metrics-chart {
      &-track-information {
        height: 100%;
        &.track-release {
          left: 16%;
        }
        &.track-hits {
          left: 59%;
        }
      }
      &-discovery {
        height: 100%;
        left: 29%;
      }
    }
  }
  @media (max-width: 1480px) and (min-width: 1396px) {
    .artist-image {
      padding-right: 0;
      &--img {
        width: 90%;
      }
    }
    &-title {
      margin-left: 0px;
      font-size: 36px;
    }
    &-subtitle {
      font-size: 19px;
    }
    &-metrics-chart {
      &-track-information {
        height: 95%;
        &.track-release {
          left: 16%;
        }
        &.track-hits {
          left: 59%;
        }
      }
      &-discovery {
        height: 95%;
        left: 29%;
      }
    }
  }
  @media (max-width: 1395px) and (min-width: 1321px) {
    .artist-image {
      padding-right: 0;
      &--img {
        width: 90%;
      }
    }
    &-title {
      margin-left: 0px;
      font-size: 36px;
    }
    &-subtitle {
      font-size: 19px;
    }
    &-metrics-chart {
      &-track-information {
        height: 89%;
        &.track-release {
          left: 16%;
        }
        &.track-hits {
          left: 60%;
        }
      }
      &-discovery {
        height: 89%;
        left: 30%;
      }
    }
  }
  @media (max-width: 1320px) and (min-width: 1281px) {
    .artist-image {
      padding-right: 0;
      &--img {
        width: 90%;
      }
    }
    &-title {
      margin-left: 0px;
      font-size: 34px;
    }
    &-subtitle {
      font-size: 18px;
    }
    &-metrics-chart {
      &-legends {
        width: 65%;
        margin-top: 15px;
        &__label p {
          font-size: 15px;
        }
      }
      &-track-information {
        height: 84%;
        &.track-release {
          left: 16%;
          p{
            margin-top: 40px;
          }
        }
        &.track-hits {
          left: 59%;
          p{
            margin-top: 40px;
          }
        }
      }
      &-discovery {
        height: 84%;
        left: 30%;
        p{
          margin-top: 40px;
        }
      }
    }
  }
  @media (max-width: 1280px) and (min-width: 1207px) {
    padding-bottom: 200px;
    .artist-image {
      padding-right: 0;
      object-fit: cover;
      max-width: 25%;
      flex: 0 0 25%;
      &--img {
        height: 100%;
        width: 100%;
      }
    }
    &-information-container {
      min-width: 75%;
      flex: 0 0 75%;
    }
    &-title {
      margin-left: 15px;
      font-size: 34px;
    }
    &-subtitle {
      font-size: 16px;
    }
    &-metrics-chart {
      &-legends {
        width: 65%;
        margin-top: 15px;
        &__label p {
          font-size: 15px;
        }
      }
      &-track-information {
        height: 92%;
        &.track-release {
          left: 16%;
          p{
            margin-top: 40px;
          }
        }
        &.track-hits {
          left: 59%;
          p{
            margin-top: 40px;
          }
        }
      }
      &-discovery {
        height: 92%;
        left: 29%;
        p{
          margin-top: 40px;
        }
      }
    }
  }
  @media (max-width: 1206px) and (min-width: 1151px) {
    padding-bottom: 150px;
    .artist-image {
      padding-right: 0;
      object-fit: cover;
      max-width: 25%;
      flex: 0 0 25%;
      &--img {
        height: 100%;
        width: 100%;
      }
    }
    &-information-container {
      min-width: 75%;
      flex: 0 0 75%;
    }
    &-title {
      margin-left: 15px;
      font-size: 34px;
    }
    &-subtitle {
      font-size: 16px;
    }
    &-metrics-chart {
      &-legends {
        width: 65%;
        margin-top: 15px;
        &__label p {
          font-size: 15px;
        }
      }
      &-track-information {
        height: 86%;
        &.track-release {
          left: 16%;
          p{
            margin-top: 40px;
          }
        }
        &.track-hits {
          left: 59%;
          p {
            margin-top: 40px;
          }
        }
      }
      &-discovery {
        height: 86%;
        left: 30%;
        p{
          margin-top: 40px;
        }
      }
    }
  }
  @media (max-width: 1150px) and (min-width: 1025px) {
    padding-bottom: 130px;
    .artist-image {
      padding-right: 0;
      object-fit: cover;
      max-width: 25%;
      flex: 0 0 25%;
      &--img {
        height: 100%;
        width: 100%;
      }
    }
    &-information-container {
      min-width: 75%;
      flex: 0 0 75%;
    }
    &-title {
      margin-left: 15px;
      font-size: 34px;
    }
    &-subtitle {
      font-size: 16px;
    }
    &-metrics-chart {
      &-legends {
        width: 65%;
        margin-top: 15px;
        &__label p {
          font-size: 15px;
        }
      }
      &-track-information {
        height: 82%;
        &.track-release {
          left: 16%;
          p{
            margin-top: 30px;
          }
        }
        &.track-hits {
          left: 59%;
          p {
            margin-top: 30px;
          }
        }
      }
      &-discovery {
        height: 82%;
        left: 32%;
        p{
          margin-top: 30px;
        }
      }
    }
  }
  @media (max-width: 1024px) and (min-width: 961px) {
    padding-bottom: 90px;
    .artist-image {
      padding-right: 0;
      object-fit: cover;
      max-width: 25%;
      flex: 0 0 25%;
      &--img {
        height: 100%;
        width: 100%;
      }
    }
    &-information-container {
      min-width: 75%;
      flex: 0 0 75%;
    }
    &-title {
      margin-left: 15px;
      font-size: 34px;
    }
    &-subtitle {
      font-size: 16px;
    }
    &-metrics-chart {
      &-legends {
        width: 65%;
        margin-top: 15px;
        &__label p {
          font-size: 15px;
        }
      }
      &-track-information {
        height: 72%;
        &.track-release {
          left: 16%;
          p{
            margin-top: 5px;
          }
        }
        &.track-hits {
          left: 59%;
          p{
            margin-top: 5px;
          }
        }
      }
      &-discovery {
        height: 72%;
        left: 33%;
        p{
          margin-top: 5px;
        }
      }
    }
  }
  @media (max-width: 960px) and (min-width: 905px) {
    padding-bottom: 120px;
    .artist-image {
      display: none;
    }
    &-information-container {
      min-width: 100%;
      flex: 0 0 100%;
    }
    &-title {
      margin-left: 0px;
      font-size: 32px;
      text-align: center;
    }
    &-subtitle {
      font-size: 17px;
      margin-top: 20px;
    }
    &-story {
      max-width: 30%;
      flex: 0 0 30%;
    }
    &-metrics-chart {
      flex: 0 0 66%;
      max-width: 66%;
      padding: 20px 0 0 0;
      margin-left: 20px;
      &-legends {
        width: 65%;
        margin-top: 15px;
        &__label p {
          font-size: 15px;
        }
      }
      &-track-information {
        top: 5%;
        height: 80%;
        &.track-release {
          left: 16%;
          p{
            margin-top: 5px;
          }
        }
        &.track-hits {
          left: 59%;
          p{
            margin-top: 5px;
          }
        }
      }
      &-discovery {
        height: 80%;
        left: 32%;
        p{
          margin-top: 5px;
        }
      }
    }
  }
  @media (max-width: 904px) and (min-width: 855px) {
    padding-bottom: 120px;
    .artist-image {
      display: none;
    }
    &-information-container {
      min-width: 100%;
      flex: 0 0 100%;
    }
    &-title {
      margin-left: 0px;
      font-size: 32px;
      text-align: center;
    }
    &-subtitle {
      font-size: 17px;
      margin-top: 20px;
    }
    &-story {
      max-width: 25%;
      flex: 0 0 25%;
    }
    &-metrics-chart {
      flex: 0 0 70%;
      max-width: 70%;
      padding: 20px 0 0 0;
      margin-left: 20px;
      &-legends {
        width: 65%;
        margin-top: 15px;
        &__label p {
          font-size: 15px;
        }
      }
      &-track-information {
        top: 5%;
        height: 80%;
        &.track-release {
          left: 16%;
          p{
            margin-top: 5px;
          }
        }
        &.track-hits {
          left: 59%;
          p{
            margin-top: 5px;
          }
        }
      }
      &-discovery {
        height: 80%;
        left: 32%;
        p{
          margin-top: 5px;
        }
      }
    }
  }
  @media (max-width: 854px) and (min-width: 769px) {
    padding-bottom: 120px;
    .artist-image {
      display: none;
    }
    &-information-container {
      min-width: 100%;
      flex: 0 0 100%;
    }
    &-title {
      margin-left: 0px;
      font-size: 32px;
      text-align: center;
    }
    &-subtitle {
      font-size: 17px;
      margin-top: 20px;
    }
    &-story {
      max-width: 25%;
      flex: 0 0 25%;
    }
    &-metrics-chart {
      flex: 0 0 70%;
      max-width: 70%;
      padding: 20px 0 0 0;
      margin-left: 20px;
      &-legends {
        width: 65%;
        margin-top: 15px;
        &__label p {
          font-size: 15px;
        }
      }
      &-track-information {
        top: 5%;
        height: 75%;
        &.track-release {
          left: 16%;
          p{
            margin-top: 5px;
            font-size: 12px;
          }
        }
        &.track-hits {
          left: 59%;
          p{
            margin-top: 5px;
            font-size: 12px;
          }
        }
      }
      &-discovery {
        height: 75%;
        left: 33%;
        p{
          margin-top: 5px;
          font-size: 12px;
          width: 100px;
        }
      }
    }
  }
  @media (max-width: 768px) and (min-width: 641px) {
    padding-bottom: 120px;
    display: flex;
    .artist-image {
      display: none;
    }
    &-information-container {
      min-width: 100%;
      flex: 0 0 100%;
      display: flex;
      flex-direction: column;
    }
    &-title {
      margin-left: auto;
      margin-right: auto;
      font-size: 34px;
      text-align: center;
    }
    &-subtitle {
      font-size: 20px;
      margin-top: 20px;
      text-align-last: left;
    }
    &-story {
      max-width: 84%;
      flex: 0 0 84%;
      margin: auto;
    }
    &-metrics-chart-container {
      display: flex;
      flex-direction: column-reverse;
    }
    &-metrics-chart {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 20px 0 0 0;
      margin-left: 20px;
      &-legends {
        width: 65%;
        margin-top: 15px;
        &__label p {
          font-size: 15px;
        }
      }
      &-track-information {
        top: 5%;
        height: 66%;
        &.track-release {
          left: 17%;
          p{
            margin-top: 5px;
            font-size: 12px;
          }
        }
        &.track-hits {
          left: 58%;
          p{
            margin-top: 5px;
            font-size: 12px;
          }
        }
      }
      &-discovery {
        height: 67%;
        top: 4%;
        left: 30%;
        p{
          margin-top: 5px;
          font-size: 12px;
          width: 100px;
        }
      }
    }
  }
  @media (max-width: 640px) and (min-width: 481px) {
    padding-bottom: 120px;
    display: flex;
    .artist-image {
      display: none;
    }
    &-information-container {
      min-width: 100%;
      flex: 0 0 100%;
      display: flex;
      flex-direction: column;
    }
    &-title {
      margin-left: auto;
      margin-right: auto;
      font-size: 30px;
      text-align: center;
      &-dot {
        margin-top: -15px;
        display: block;
        visibility: hidden;
      }
    }
    &-subtitle {
      font-size: 16px;
      margin-top: 10px;
      text-align-last: left;
    }
    &-story {
      max-width: 84%;
      flex: 0 0 84%;
      margin: auto;
    }
    &-metrics-chart-container {
      display: flex;
      flex-direction: column-reverse;
    }
    &-metrics-chart {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 20px 0 0 0;
      margin-left: 20px;
      &-legends {
        width: 65%;
        margin-top: 15px;
        &__label p {
          font-size: 15px;
        }
      }
      &-track-information {
        top: 5%;
        height: 62%;
        &.track-release {
          left: 17%;
          p{
            margin-top: 5px;
            font-size: 12px;
          }
        }
        &.track-hits {
          left: 59%;
          p{
            margin-top: 5px;
            font-size: 12px;
          }
        }
      }
      &-discovery {
        height: 63%;
        top: 4%;
        left: 32%;
        p{
          margin-top: 5px;
          font-size: 12px;
          width: 100px;
        }
      }
    }
  }
  @media (max-width: 480px) and (min-width: 376px) {
    padding-bottom: 10px;
    display: flex;
    .artist-image {
      display: none;
    }
    &-information-container {
      min-width: 100%;
      flex: 0 0 100%;
      display: flex;
      flex-direction: column;
    }
    &-title {
      margin-left: auto;
      margin-right: auto;
      font-size: 30px;
      text-align: center;
      &-dot {
        margin-top: -15px;
        display: block;
        visibility: hidden;
      }
    }
    &-subtitle {
      font-size: 16px;
      margin-top: 10px;
      text-align-last: left;
    }
    &-story {
      max-width: 84%;
      flex: 0 0 84%;
      margin: auto;
    }
    &-metrics-chart-container {
      display: flex;
      flex-direction: column-reverse;
    }
    &-metrics-chart {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 20px 0 0 0;
      margin-left: 20px;
      &-legends {
        width: 65%;
        margin-top: 15px;
        &__label p {
          font-size: 15px;
        }
      }
      &-track-information {
        top: 5%;
        height: 56%;
        &.track-release {
          left: 26%;
          p{
            margin-top: 5px;
            font-size: 12px;
          }
        }
        &.track-hits {
          border-left: 4px dashed rgba(255, 255, 255, 0.5);
          border-right: 0;
          left: 74%;
          p{
            margin-top: 5px;
            font-size: 12px;
          }
        }
      }
      &-discovery {
        height: 56%;
        top: 4%;
        left: 46%;
        p{
          margin-top: 5px;
          font-size: 12px;
          width: 100px;
        }
      }
    }
  }
  @media (max-width: 375px) {
    padding-bottom: 10px;
    display: flex;
    .artist-image {
      display: none;
    }
    &-information-container {
      min-width: 100%;
      flex: 0 0 100%;
      display: flex;
      flex-direction: column;
    }
    &-title {
      margin-left: 30px;
      margin-right: auto;
      font-size: 30px;
      text-align: center;
      &-dot {
        margin-top: -15px;
        display: block;
        visibility: hidden;
      }
    }
    &-subtitle {
      font-size: 16px;
      margin-top: 10px;
      text-align-last: left;
    }
    &-story {
      max-width: 84%;
      flex: 0 0 84%;
      margin: auto;
    }
    &-metrics-chart-container {
      display: flex;
      flex-direction: column-reverse;
    }
    &-metrics-chart {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 20px 0 0 0;
      margin-left: 20px;
      &-legends {
        width: 85%;
        margin-top: 15px;
        &__label p {
          font-size: 15px;
        }
      }
      &-track-information {
        top: 5%;
        height: 50%;
        &.track-release {
          left: 21%;
          p{
            margin-top: 5px;
            font-size: 9px;
          }
        }
        &.track-hits {
          border-left: 4px dashed rgba(255, 255, 255, 0.5);
          border-right: 0;
          left: 64%;
          p{
            margin-top: 5px;
            font-size: 9px;
          }
        }
      }
      &-discovery {
        height: 50%;
        top: 4%;
        left: 42%;
        p{
          margin-top: 5px;
          width: 80px;
          font-size: 9px;
        }
      }
    }
  }
}
