.largeAboutText{
    text-align: center;
}

.social-icons{
    font-size: 45px;
    text-align: center;
    color: white;
}

.footer{
    background-color: #7625ad;
}

.social-icon{
    margin-left: 25px;
    margin-right: 25px;
    color:white;
}

.footer-copyright{
    background-color: #682396;
    color: white;
}

.footer-email{
    font-size: 20px;
    text-align: center;
    color: white;
}