.largeAboutText{
    text-align: center;
}

.mission-image{
    width: 80%;
}

.mission-headder{
    margin-bottom: 30px;
}

.mission-list-item{
    margin-top: 20px;
    font-size: 20px;
    padding-right: 30px;
}

@media only screen and (max-width: 600px) {
    .mission-image{
        display: block;
        margin: 0 auto;
    }

    #mission{
        text-align: center;
    }

    .mission-list-item{
        padding: 0px;
    }
}