.testimonialImage{
    width: 100px;
    height: 100px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    object-fit: cover;
}

.testimonialName{
    text-align: center;
    margin-bottom: 0px;
    margin-top: 30px;
    font-weight: bold;
    font-size: 35px;
    color: #7625ad;
    text-decoration: underline;
}

.testimonialText{
    text-align: center;
    font-size: 20px;
    color: rgb(10,10,10);
    text-decoration: none !important;
}

.testimonial{
    padding-bottom: 40px;
    padding-top: 40px;
    padding-left: 0px;
    padding-right: 0px;
    height: 430px !important;
}

.testimonialTitle{
    text-align: center;
    margin-bottom: 30px;
    margin-top: 15px;
    color: #7625ad !important;
    font-size: 20px;
}

.jobTitle{
    text-align: center;
    margin-top: 20px;
}

.testimonialJobImage{
    height: 50px;
}

.testimonialTitle{
    margin: 0 auto;
    display: inline-block;
    width: 300px !important;
    vertical-align: middle;
}

@media only screen and (max-width: 600px) {
    .testimonialTitle{
        text-align: center;
        margin-bottom: 30px;
        color: rgb(150,150,150);
        text-decoration: none !important;
        font-size: 10px;
    }

    .testimonialName{
        text-align: center;
        margin-bottom: 0px;
        margin-top: 30px;
        font-weight: bold;
        font-size: 25px;
    }
    
    .testimonialText{
        text-align: center;
        font-size: 14px;
        color: rgb(10,10,10);
        text-decoration: none !important;
    }

    .testimonial{
        padding-bottom: 40px;
        padding-top: 40px;
        padding-left: 0px;
        padding-right: 0px;
        height: 450px !important;
    }

    .testimonialJobImage{
        display: block;
        margin: 0 auto;
    }

    #firstTitle{
        margin-bottom: 10px;
    }
}