.not-found-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
background: linear-gradient(90deg, rgba(148,70,218,1) 0%, rgba(205,104,196,1) 35%, rgba(234,48,123,1) 100%);
  }
  
  .not-found-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 510px;
    border-radius: 10px;
    background-color: rgba(0,0,0,.5);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .not-found-left {
    flex: 1;
    padding: 20px;
  }
  
  .not-found-heading {
    font-size: 20px;
    margin-bottom: 10px;
    color: #fff;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .not-found-description {
    font-size: 18px;
    color: rgb(118, 37, 173);
  }
  
  .not-found-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .not-found-button:hover {
    background-color: #0056b3;
  }
  
  .not-found-right {
    flex: 1;
    text-align: center;
  }
  
  .not-found-image {
    max-width: 100%;
    max-height: 300px; 
  }
  

  @media (max-width:810px) {
    .not-found-content{
        width: 95%;
    }
    .not-found-heading {
        font-size: 16px;
    }
  }
  @media (max-width:610px) {
    .not-found-content{
        display: flex;
        flex-direction: column;
        flex-direction: column-reverse;
    }
    .imgGiphy{
        width: 100%;
    }
    
  }