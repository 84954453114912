.navbar{
    width: 100%;
    color: #BDBAFF;
    min-height: 70px;
    padding-right: 0px;
}

.navbar-top{
    background: rgba(72,72,72,0.5) !important;
}

.navbar-scroll{
    background: rgba(72,72,72,0.9) !important;
}

.navbar-brand{
    color: white !important;
}

.nav-item{
    color: white !important;
    font-size: 20px;
    margin-left: 40px;
}

#SXSW-btn img{
    height: 15px;
    vertical-align: middle;
    margin-bottom: 4px;
}

#SXSW-btn{
    background-color: white;
    padding-left: 15px !important;
    padding-right: 15px !important;
    font-size: 14px !important;
    color: black !important;
    border-radius: 18px;
    text-align: center;
    height: 35px;
    margin-top: 8px;
}

.last{
    margin-right: 80px !important;
}

.nav-item:hover{
    color: #BBB !important;
    text-decoration: underline;
}

.nav-item.active{
    color: #BBB !important;
    text-decoration: underline;
}

.navbar-logo{
    height: 50px !important;
    width: auto;
    margin-right: 10px;
}

.navbar-brand-text{
    font-size: 32px;
}

.navbar-toggler-icon{
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}

@media only screen and (max-width: 600px) {
    .navbar-top{
        background: rgba(72,72,72,0.9) !important;
    }

    #SXSW-btn-nav-item{
        margin-left: -10px;
        padding-right: 10px;
    }
}