.bigImage{
    width: 100%;
    height: 100%;
    object-fit: cover;
    padding: 0px;
    margin: 0px;
}

.shadow-custom{
    position: absolute;
    top: 20%;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0px;
    margin: 0px;
    background: linear-gradient(0deg, white 0%, rgba(255,255,255,0) 35%);
}

.bigImage-wrapper{
    height: 100vh;
}

@media only screen and (max-width: 600px) {
    .bigImage-wrapper{
        height: 80vh;
    }
}


.overlayText{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #404040;
    font-size: 50px;
    font-weight: bold;
    text-align: center;
    /*text-shadow: 2px 2px 4px #000000;*/
    background-color: #7926ae;
    height: 400px;
    width: 400px;
    border-radius: 50%;
}

.big-overlay-text{
    color: white;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
