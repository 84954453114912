.App{
  font-family: 'Alata', sans-serif;
}

.g-section{
  background-color: rgb(240, 240, 240);
  margin: 0px!important;
}

.headder-text{
  color: #7625ad;
  text-decoration: underline;
}

.subheadder-text{
  color: #6e30ff; 
}

html {
  scroll-behavior: smooth;
  /*overflow-x: hidden;*/
}

.row{
  max-width: 100% !important;
}

a {
  color: #c3c3c3;
}

.gray-section{
  padding-left: 200px;
  padding-right: 200px;
  padding-top: 160px;
  padding-bottom: 160px;
  background-color: #f4f4f5;
  color: rgb(150,150,150);
  max-width: 100%;
}

.section{
  padding-left: 200px;
  padding-right: 200px;
  padding-top: 160px;
  padding-bottom: 160px;
  background-color: white;
  max-width: 100%;
  color: rgb(150,150,150);
  margin-right: 0px;
}

.App{
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .gray-section{
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 16px;
    padding-bottom: 16px;
    margin-right: 0px !important;
    margin-left: 0px !important;
  } 

  .section{
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 16px;
    padding-bottom: 16px;
    margin-right: 0px !important;
    margin-left: 0px !important;
  }

  #about{
    scroll-margin: -200px;
  }
}
.navbar-height{
  padding-top: 76px;
}